import React from 'react'
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';
import Holder from './../Holder';
import footerIcons from './img/icons.svg';
import Icons from './../../../../base_components/Icons';
import Text from "../../../../base_components/Text";

import './footer.css'
import {
  REACT_APP_CONTACT_EMAIL,
  REACT_APP_ADDRESS_US,
  REACT_APP_SITENAME
} from './../../../../config';

const GuaranteeList = () => {
  return (
  <div className="footer__guarantee-list">
{/*
    <img className="guarantee-list-img paypal"
         src={require("./img/pay.png")}
         srcSet={`${require("./img/pay.png")}, ${require("./img/pay@2x.png")} 2x`}
         alt=""/>
*/}
    <img
      loading="lazy"
      className="footer__guarantee-img guarantee"
      src={require("./img/guarantee.png")}
      srcSet={`${require("./img/guarantee.png")}, ${require("./img/guarantee@2x.png")} 2x`}
      width="65"
      height="76"
      alt=""
    />
    <img
      loading="lazy"
      className="footer__guarantee-img mccafee"
      src={require("./img/mccafee.png")}
      srcSet={`${require("./img/mccafee.png")}, ${require("./img/mccafee@2x.png")} 2x`}
      width="103"
      height="38"
      alt=""
    />
  </div>
  )
};

const PaymentList = () => {
  return (
    <ul className="footer__payment-list">
      <li className="footer__payment-item">
        <img
          loading="lazy"
          className="footer__payment-img"
          src={require('./img/payment/visa.png')}
          srcSet={`${require("./img/payment/visa.png")}, ${require("./img/payment/visa@2x.png")} 2x`}
          width="54"
          height="35"
          alt=""
        />
      </li>
      <li className="footer__payment-item">
        <img
          loading="lazy"
          className="footer__payment-img"
          src={require('./img/payment/mc.png')}
          srcSet={`${require("./img/payment/mc.png")}, ${require("./img/payment/mc@2x.png")} 2x`}
          width="54"
          height="35"
          alt=""
        />
      </li>
      <li className="footer__payment-item">
        <img
          loading="lazy"
          className="footer__payment-img"
          src={require('./img/payment/ae.png')}
          srcSet={`${require("./img/payment/ae.png")}, ${require("./img/payment/ae@2x.png")} 2x`}
          width="54"
          height="35"
          alt=""
        />
      </li>
      <li className="footer__payment-item">
        <img
          loading="lazy"
          className="footer__payment-img"
          src={require('./img/payment/discover.png')}
          srcSet={`${require("./img/payment/discover.png")}, ${require("./img/payment/discover@2x.png")} 2x`}
          width="54"
          height="35"
          alt=""
        />
      </li>
{/*
      <li className="payment-item">
        <img className="payment-img" src={require('./img/payment/pp.png')}
             srcSet={`${require("./img/payment/pp.png")}, ${require("./img/payment/pp@2x.png")} 2x`} alt=""/>
      </li>
*/}
      <li className="footer__payment-item">
        <img
          loading="lazy"
          className="footer__payment-img"
          src={require('./img/payment/google-pay.png')}
          srcSet={`${require("./img/payment/google-pay.png")}, ${require("./img/payment/google-pay@2x.png")} 2x`}
          width="54"
          height="35"
          alt=""
        />
      </li>
      <li className="footer__payment-item">
        <img
          loading="lazy"
          className="footer__payment-img"
          src={require('./img/payment/applepay.png')}
          srcSet={`${require("./img/payment/applepay.png")}, ${require("./img/payment/applepay@2x.png")} 2x`}
          width="54"
          height="35"
          alt=""
        />
      </li>
    </ul>
  )
};

export const Footer = (props) => {
  return (
    <footer className={classnames('footer-main')}>
      <LazyLoad>
        <Icons icons={footerIcons} className='svg-icon desktop-only' iconName={`footer-shape1`} />
        <Icons icons={footerIcons} className='svg-icon desktop-only' iconName={`footer-shape2`} />
        <Icons icons={footerIcons} className='svg-icon mobile-only' iconName={`footer-shape1-mob`} />
        <Icons icons={footerIcons} className='svg-icon mobile-only' iconName={`footer-shape2-mob`} />
      </LazyLoad>
      <Holder>
        <LazyLoad height='60'>
          <Icons icons={footerIcons} className='svg-icon' iconName={props.footerLogo || `footer-logo`} />
        </LazyLoad>
        <div className="middle-part">
          <p className="footer-content1">Made with love in San Francisco<span className="desktop-only">. Send us an email to</span> <a className='footer-content-link' href={"mailto:" + REACT_APP_CONTACT_EMAIL}>{REACT_APP_CONTACT_EMAIL}</a></p>
          <p className="footer-content2">{props.content2 || <Text>By accessing this website you agree to our Terms&Conditions, Privacy and Cookie Policies.</Text>}</p>
          <address className="footer-address">
            {REACT_APP_ADDRESS_US && <p><span className="footer-address-title">Our US address: </span>{REACT_APP_ADDRESS_US}</p>}
          </address>
          {props.children}
          <div className="footer__labels-block">
            <LazyLoad>
              <GuaranteeList />
              <PaymentList />
            </LazyLoad>
          </div>
          <div className="footer-copyright">{props.copyright || <Text>&copy; 2025 {REACT_APP_SITENAME}</Text>}</div>
        </div>
      </Holder>
    </footer>
  )
};

export const FooterIcon = ({iconName, ...rest}) => {
  return <LazyLoad><Icons icons={footerIcons} className='svg-icon' iconName={iconName} /></LazyLoad>;
};


export const FooterType2 = (props) => {
  return (
    <footer className={classnames('footer-type2',props.className)}>
      <LazyLoad>
        <Icons icons={footerIcons} className='svg-icon footer-shape desktop-only' iconName={`footer-shape1`} />
        <Icons icons={footerIcons} className='svg-icon footer-shape desktop-only' iconName={`footer-shape2`} />
        <Icons icons={footerIcons} className='svg-icon footer-shape mobile-only' iconName={`footer-shape1-mob`} />
        <Icons icons={footerIcons} className='svg-icon footer-shape mobile-only' iconName={`footer-shape2-mob`} />
      </LazyLoad>
      <Holder>
        <LazyLoad height='60'>
          <Icons icons={footerIcons} className='svg-icon' iconName={props.footerLogo || `footer-logo`} />
        </LazyLoad>
        <div className="middle-part">
          {props.children}

          <div className="footer__labels-block">
            <LazyLoad>
              <GuaranteeList />
              <PaymentList />
            </LazyLoad>
          </div>

          <div className="footer-copyright">{props.copyright || <Text>&copy; 2025 {REACT_APP_SITENAME}</Text>}</div>
        </div>
      </Holder>
    </footer>
  )
};


export const FooterType3 = (props) => {
  return (
    <footer className={classnames('footer_type3',props.className)}>
      <Holder>
        <LazyLoad height='20'>
          <Icons icons={footerIcons} className='logo-icon' iconName={props.footerLogo || `footer-logo2`} />
        </LazyLoad>
        <div className="middle-part">
          {props.children}

          <div className="footer__labels-block">
            <LazyLoad>
              <GuaranteeList />
              <PaymentList />
            </LazyLoad>
          </div>

          <div className="footer-copyright">{props.copyright || <Text>&copy; 2020 {REACT_APP_SITENAME}</Text>}</div>
        </div>
      </Holder>
    </footer>
  )
};

