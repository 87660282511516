import React from 'react';
import LazyLoad from 'react-lazyload';
import classnames from "classnames";
import Holder from './../Holder';
import Text from '../../../../base_components/Text';

import './ourPrices.css';

export default (props) => {
  const { titleTag, subtitleTag } = props;
  const TitleTag = titleTag ? titleTag : 'h2';
  const SubtitleTag = subtitleTag ? subtitleTag : 'div';
  return (
    <div className={classnames('our-prices', {[`our-prices_${props.theme}`]: props.theme})}>
      <Holder className="holder_2">
        <div className="our-prices-wrapper">
          <div className="our-prices-msg-block">
            <TitleTag className="h3 our-prices-title">Our prices</TitleTag>
            <div className="our-prices-msg">
              {props.msg1 || (
                <Text>
                  You only pay for the time your Nerd spends working on your task: the platform fee (11%) is already included! Tell us more about your task & we'll estimate the price. If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.
                </Text>
              )}
            </div>
            <img
              loading="lazy"
              className="our-prices-img mobile-only"
              src={require(`./img/${props.img ? props.img : 'thumb.svg'}`)}
              width="181"
              height="181"
              alt={''}
            />
            <div className="our-prices-subtitle">
              {props.msg2 || (
                <SubtitleTag>
                  We're only happy if you
                  <br className="mobile-only" /> are satisfied!
                </SubtitleTag>
              )}
            </div>
            <div className="our-prices-msg">100% Money-Back Guarantee</div>
          </div>
          <img
            loading="lazy"
            className="our-prices-img desktop-only"
            src={require(`./img/${props.img ? props.img : 'thumb.svg'}`)}
            width="181"
            height="181"
            alt={''}
          />
        </div>
      </Holder>
    </div>
  );
};

export const OurPrices2 = (props) => <div className={classnames('our-prices-block', props.className)}> {/* nerdifyit.com/nerdify-reviews*/}
  <div className="holder">
    <div className="our-prices-wrapper">
      <div className="our-prices-text">
        <h2 className="title">Our prices</h2>
        <div className="our-price-msg1">
          {props.descr ?? "You only pay for the time your Nerd spends working on your task: the platform fee (11%) is already included! Tell us more about your task & we'll estimate the price. If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat."}          
        </div>
        <img className="satisfaction-img mobile-only" src={require(`./img/satisfaction@2x.png`)} alt={'Nerdify customer satisfaction guarantee'} />

        <div className="subtitle">We're only happy if<br className="mobile-only"/> you are satisfied!</div>
        <div className="our-price-msg2">In case you have any concerns regarding the quality of the service
          provided,<br className="desktop-only"/> contact us via text messages and we will pass your request to our Quality<br className="desktop-only"/> Assurance team asap. Please visit our <a className="link" href="/refunds">Refund Policy</a> page to learn more.
        </div>
      </div>
      <img className="satisfaction-img desktop-only" src={require(`./img/satisfaction@2x.png`)} alt={'Nerdify customer satisfaction guarantee'} />
    </div>
  </div>
</div>;


export const OurPricesClp3 = (props) => <div className={classnames('our-prices_clp3', props.className)}>
  <div className="holder">
    <div className="our-prices-title">Our prices</div>
    <div className="our-prices-msg">
      {props.msg1 || (
        <Text>
          You only pay for the time Nerd spends working on your task:
          the platform fee (11%) is already included! Tell us more about
          your task & we'll estimate the price. If this is your first
          time using Nerdify, you qualify for a guaranteed welcome
          discount. Ask the details in chat.
        </Text>
      )}
    </div>
    <div className="our-prices-subtitle">
      {props.msg2 || (
        <Text>We're only happy if you are satisfied!</Text>
      )}
    </div>
    <LazyLoad height={80}>
      <img
        className="our-prices-img"
        src={require(`./img/moneyback.png`)} srcSet={require(`./img/moneyback@2x.png`)+' 2x'}
        alt={''}
      />
    </LazyLoad>
  </div>
</div>;
