import React, { Component, Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder'

import { Header1, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import { Hero15, HeroIcon, HeroImg } from '../../../../themes/nerdify/components/Hero';
import SaveTime, {SaveTimeIconImg} from '../../../../themes/nerdify/components/SaveTime';
import OurPrices from '../../../../themes/nerdify/components/OurPrices';
import PayList from '../../../../themes/nerdify/components/PayList';
import Text from '../../../../base_components/Text'
import HowSlider, { HowSliderList, HowSliderImg } from '../../../../themes/nerdify/components/HowSlider';
import { Hiw7, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw';
import SlickSlider1 from '../../../../themes/nerdify/components/SlickSlider1';
import SlickSlider2 from '../../../../themes/nerdify/components/SlickSlider2';
import * as demoCardsRecords from '../../blocks/DemoCards';
import { DemoCards2 } from '../../blocks/DemoCards';
import DemoChat from '../../../../themes/nerdify/components/DemoChat';
import { SectionBlock, Section11 } from '../../../../themes/nerdify/components/Section';
import Footer from '../../blocks/Footer';
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';
import { Faq2, Accordion, AccordionHead, AccordionBody, AccordionItem, AccordionIcon, AccordionItemHolder } from '../../../../themes/nerdify/components/Faq';
import Icon from '../../../../themes/nerdify/components/Icons';
import { TextBack7 } from '../../blocks/TextBack'
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import { TopNerdsInfo4 } from '../../../../themes/nerdify/components/TopNerdsInfo';
import DemoSubjects from '../../../../themes/nerdify/components/DemoSubjects';
import { How2, HowList, HowImg2 } from '../../../../themes/nerdify/components/How';
import { useMobile } from '../../../../hooks';

import LocalesPage from '../LocalesPage';
import BottomModal from './../../../../components/BottomModal';
import { HnavMob2 } from '../../blocks/HnavMob';

const slider1Items = [
  {
    title: 'Proofreading',
    msg: 'Need someone to check my language and punctuation!'
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk on the importance of Philosophy edu by Wednesday. Can you help?'
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.'
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?'
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.'
  },
  {
    title: 'Tutor in Statistics',
    msg: <Fragment>I don't understand inferential statistics methods. Can u explain me the methodology?</Fragment>
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.'
  },
  {
    title: 'Tutor in Accounting',
    msg: <Fragment>Need an accounting homework help: a tutor to go though my calculations with me to make sure they're correct.</Fragment>
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?'
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.'
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could understand my assignment in sociology.'
  },
  {
    title: 'Formatting',
    msg: 'I am almost done writing my research paper and need someone to check if formatted it properly.'
  },
  {
    title: 'Application form',
    msg: 'I want to ask for tips on how to properly complete an application form for the exchange program.'
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?'
  },
  {
    title: 'English Tutor',
    msg: <Fragment>I'm very bad in writing; help me find an English tutor to improve my skills, please.</Fragment>
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, is it possible for you?'
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?'
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Nanobiology.'
  },
  {
    title: 'Proofreading',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!'
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?'
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can I get that done?'
  },
  {
    title: 'Biology Tutor',
    msg: <Fragment>I'm stuck with my homework and need help. Do you have an online tutor in Biology?</Fragment>
  },
  {
    title: 'Speech',
    msg: 'I need a support of your Nerds. Need help to write a speech for company wide meeting in easy-going manner.'
  },
];

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
}

class Landing2 extends Component {
  constructor(props) {
    super(props);
    this.openHiwModal = this.openHiwModal.bind(this);
    this.closeHiwModal = this.closeHiwModal.bind(this);
  }

  openHiwModal() {
    this.howItWorksModalRef.show();
  }

  closeHiwModal() {
    this.howItWorksModalRef.hide();
  }

  render() {
    const { pageCfg } = this.props;

    return (
      <WithHook>
        {({
          isMobile
        }) => {
          return (
            <LocalesPage {...this.props}>
              <div className="landing">
                <Header1>
                  <Holder>
                    <NavLink className='logo-link' to='/'>
                      <HeaderIcon iconName='logo-dark' />
                    </NavLink>
                    {pageCfg.hasNav && <HnavMob2 />}
                  </Holder>
                </Header1>

                <Hero15>
                  <div className="hero-main">
                    <div className="holder">
                      <h1 className="hero__title">
                        {!isMobile ?
                          pageCfg.headline || <Text>Confused by your Homework?</Text>
                          :
                          <Text>Overwhelmed<br/> by urgent tasks?</Text>
                        }
                      </h1>
                      <h5 className="hero__subtitle">
                        {!isMobile ?
                          pageCfg.subtitle || <Text>Get instant access to Nerds<br className="mobile-only" /> who can help</Text>
                          :
                          <Text>Install the App and get access<br/> to Nerds who can help</Text>
                        }
                      </h5>
                      {isMobile ? <>
                          <a href="https://app.go-nerdify.com/gs/fJc4" className="appstore-link" target="_blank" rel="noopener noreferrer">
                            <HeroIcon className="appstore-icon" img={"app-store-black.svg"} />
                          </a>
                          <HeroImg className="iphone-img" img={"phone.png"} img2x={"phone@2x.png"} img3x={"phone@3x.png"} />
                        </>
                        :
                        <TextBack7 {...this.props} subTheme={6} />
                      }
                    </div>
                  </div>
                  {isMobile && <div className="hero-textback-wrapper">
                    <div className="holder">
                      <div className="hero-textback-title">You can also use Nerdify<br/>
                        via text messages</div>
                      <TextBack7 {...this.props} subTheme={6} />
                    </div>
                  </div>
                  }
                  <div className="hero-rate">
                    <div className="holder">
                      <div className="hero-rate-msg">76,750 users rated Nerds 4.7/5</div>
                      <div className="hero-rate-icon" />
                    </div>
                  </div>
                </Hero15>

                <Hiw7 subTheme={1}>
                  <div className="holder hiw-holder1">
                    <h3 className='h3'>Fast. Simple.<br className="mobile-only" /> Always with you.</h3>
                    <div className="desktop-only">
                      <HiwList>
                        <HiwItem>
                          <HiwImg img={'hiw1'} />
                          <HiwText>
                            <p className="p">Tell us what you need help
                              with or send a pic with instructions</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw2'} />
                          <HiwText>
                            <p className="p">Get the best-fit Nerd
                              assigned for your task as soon
                              as possible</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw3'} />
                          <HiwText>
                            <p className="p">Agree to the quote and make
                              a secure payment through
                              the platform</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw20'} />
                          <HiwText>
                            <p className="p">Get help and become one more happy <br className="desktop-only" />  Nerdify client</p>
                          </HiwText>
                        </HiwItem>
                      </HiwList>
                    </div>
                    <div className="hiw-subtitle mobile-only">Drop us a simple message or a picture with your task &amp; get best Nerd assigned</div>
                    <div className="hiw-demochat">
                      <DemoChat
                        items={[
                          { text: 'Don\'t understand my task 😱', type: 'user' },
                          { text: 'Just drop a pic of a part you are stuck on. 📸', type: 'nerd' },
                          { type: 'img' },
                          { text: 'Have a class on Monday 🙏', type: 'user' },
                          { text: 'Got it 👌 One of our top History Nerds is ready to clarify it for you asap!', type: 'nerd' }
                        ]}
                      />
                    </div>

                    <button className="button__type14 mobile-only" type="button" onClick={this.openHiwModal}>
                      <Icon className={`svg-icon`} iconName="small-arrow" />
                      <div>Learn more details</div>
                    </button>

                    <BottomModal ref={(ref) => { this.howItWorksModalRef = ref; }}>
                      <div className="hiw-bottom-modal">
                        <button className="hiw-modal-close" onClick={this.closeHiwModal}>
                          <Icon className='svg-icon' iconName='close-icon3' />
                        </button>

                        <h3 className='h3 mobile-only'>Here is how it works</h3>
                        <HiwList>
                          <HiwItem>
                            <HiwImg img={'hiw1'} />
                            <HiwText>
                              <p className="p">Tell us what you need help
                                with or send a pic with instructions</p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw2'} />
                            <HiwText>
                              <p className="p">Get the best-fit Nerd
                                assigned for your task as soon
                                as possible</p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw3'} />
                            <HiwText>
                              <p className="p">Agree to the quote and make
                                a secure payment through
                                the platform</p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw20'} />
                            <HiwText>
                              <p className="p">Get help and become one more happy <br className="desktop-only" />  Nerdify client</p>
                            </HiwText>
                          </HiwItem>
                        </HiwList>
                      </div>
                    </BottomModal>
                  </div>

                  <div className="hr_4" />

                  <div className="holder hiw-holder2">
                    <p className="p__tiny hiw-honor-line">Nerds can do anything to help with your college or work life,<br className="desktop-only" /> as long as it's legal and doesn't violate any policy or our <Link className="link" to="/honor-code">Honor Code</Link>. See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
                  </div>
                </Hiw7>

                <Press className={"press__2 press__2_1"}>
                  <div className="press-wrapper">
                    <div className="p p__title press__title">Featured on</div>

                    <PressList>
                      <PressItem>
                        <PressIcon iconName={'forbes'} />
                      </PressItem>
                      <PressItem>
                        <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'wired'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'tech-co'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href='https://www.nextmedia-hamburg.de/wp-content/uploads/2018/12/nextMedia.Kompass_Trendreport_Oktober_2016.pdf' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'next-media'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'venturebeat'} />
                        </a>
                      </PressItem>
                    </PressList>
                  </div>
                </Press>

                <SaveTime className="save-time-block">
                  <div className="holder">
                    <div className="h3 save-time-title">Save Time and Effort<br className="mobile-only"/> with Nerdify</div>
                    <div className="save-time-wrapper">

                      <ul className="save-time-list">
                        <li className="list-title">Spend less time...</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No confusing forms to fill in</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No lengthy search for the right freelancer</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No stressful price negotiations</div>
                        </li>
                      </ul>

                      <ul className="save-time-list">
                        <li className="list-title">…get better results</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">An instant reply to any request</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Just drop a photo, video or text with a task</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Live updates via SMS/Messenger for free</div>
                        </li>
                      </ul>

                    </div>
                  </div>
                </SaveTime>

                <Section11>
                  <TopNerdsInfo4
                    title={'We select only the top 2% of Nerds in each field'}
                    subtitle={<>Nerds are freelancers who passed a rigid examination and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> before joining the Nerdify platform.</>} />

                  <DemoSubjects />

                  <Nerds2 indent={'pt50 pb40'}>
                    <div className='nerds__title'>…from some of the <span className="strong">TOP universities</span></div>
                  </Nerds2>
                </Section11>

                <SlickSlider1 items={slider1Items}>
                  <p className="p__tiny slick-honor-line">Nerds can do anything to help with your college or work life,<br className="desktop-only" /> as long as it's legal and doesn't violate any policy or our <Link className="link" to="/honor-code">Honor Code</Link>. See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
                </SlickSlider1>

                {!isMobile && (
                  <How2>
                    <h3 className={'h3'}>We help people just like<br className="mobile-only" /> you every day</h3>

                    <HowList
                      {...this.props}
                      items={{
                        Applicants: [
                          {
                            text: <Text><div className="how-name">Josh, 18</div><div className="how-msg">Dreaming to get into the University of Arts London</div></Text>,
                            img: <HowImg2 img={'Josh,18'} />
                          },
                          {
                            text: <Text><div className="how-name">Mia, 23</div><div className="how-msg">Getting prepared for her first job interview</div></Text>,
                            img: <HowImg2 img={'Mia,23'} />
                          },
                          {
                            text: <Text><div className="how-name">Sam, 42</div><div className="how-msg">Preparing a speech for the company board to get promoted.</div></Text>,
                            img: <HowImg2 img={'Sam,42'} />
                          },
                        ],
                        Students: [
                          {
                            text: <Text><div className="how-name">Jessica, 22</div><div className="how-msg">Juggling a part-time job and college studies</div></Text>,
                            img: <HowImg2 img={'Jesica,22'} />
                          },
                          {
                            text: <Text><div className="how-name">Etan, 35</div><div className="how-msg">Getting a degree in the online university</div></Text>,
                            img: <HowImg2 img={'Etan,35'} />
                          },
                          {
                            text: <Text><div className="how-name">Advik, 25</div><div className="how-msg">Taking part in an exchange program</div></Text>,
                            img: <HowImg2 img={'Advik,25'} />
                          },
                        ],
                        Businesses: [
                          {
                            text: <Text><div className="how-name">Khan, 28</div><div className="how-msg">Doing market research for his new product</div></Text>,
                            img: <HowImg2 img={'Khan,28'} />
                          },
                          {
                            text: <Text><div className="how-name">Laura, 35</div><div className="how-msg">Getting ready for a new customer presentation</div></Text>,
                            img: <HowImg2 img={'Laura,35'} />
                          },
                          {
                            text: <Text><div className="how-name">Mike, 47</div><div className="how-msg">Working on the new product strategy</div></Text>,
                            img: <HowImg2 img={'Mike,47'} />
                          },
                        ],
                      }}
                    >
                    </HowList>
                  </How2>
                )}

                {isMobile && (
                  <HowSlider>
                    <h3 className={'h3'}>We help people just like<br className="mobile-only" /> you every day</h3>

                    <HowSliderList
                      {...this.props}
                      items={{
                        Applicants: [
                          {
                            name: 'Josh, 18',
                            text: 'Dreaming to get into the University of Arts London',
                            img: <HowSliderImg img={'Josh,18'} />
                          },
                          {
                            name: 'Mia, 23',
                            text: 'Getting prepared for her first job interview',
                            img: <HowSliderImg img={'Mia,23'} />
                          },
                          {
                            name: 'Sam, 42',
                            text: 'Preparing a speech for the company board to get promoted.',
                            img: <HowSliderImg img={'Sam,42'} />
                          },
                        ],
                        Students: [
                          {
                            name: 'Jessica, 22',
                            text: 'Juggling part-time work and college studies',
                            img: <HowSliderImg img={'Jesica,22'} />
                          },
                          {
                            name: 'Etan, 35',
                            text: 'Getting a degree in the online university',
                            img: <HowSliderImg img={'Etan,35'} />
                          },
                          {
                            name: 'Advik, 25',
                            text: 'Taking part in an exchange program',
                            img: <HowSliderImg img={'Advik,25'} />
                          },
                        ],
                        Businesses: [
                          {
                            name: 'Khan, 28',
                            text: 'Doing market research for his new product',
                            img: <HowSliderImg img={'Khan,28'} />
                          },
                          {
                            name: 'Laura, 35',
                            text: 'Getting ready for new customer presentation',
                            img: <HowSliderImg img={'Laura,35'} />
                          },
                          {
                            name: 'Mike, 47',
                            text: 'Working on the new product strategy',
                            img: <HowSliderImg img={'Mike,47'} />
                          },
                        ],
                      }}
                    >
                    </HowSliderList>
                  </HowSlider>
                )}

                <SlickSlider2 />

                <SectionBlock theme={2} indent="pb80m pt70d pb70d">
                  <Holder className="holder_3">
                    <div className='section-title'>…or send your personal<br className="mobile-only" /> help request</div>
                    <TextBack7 {...this.props} />
                  </Holder>
                </SectionBlock>

                <DemoCards2
                  records={ pageCfg.demoCards ? demoCardsRecords[pageCfg.demoCards] : '' }
                />

                <OurPrices />

                <PayList />

                <Faq2>
                  <Holder>
                    <h3 className={'h3'}>FAQ</h3>
                    <Accordion>
                      <AccordionItemHolder>
                        <AccordionItem index={1}>
                          <AccordionHead>

                            <AccordionIcon icons={['plus', 'minus']} />
                            <h6 className={'h6'}>What tasks can a Nerd do?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. They can really do anything! Try it! See <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem index={2}>
                          <AccordionHead>

                            <AccordionIcon icons={['plus', 'minus']} />
                            <h6 className={'h6'}>How do you find the best-fit Nerd?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</p>
                          </AccordionBody>
                        </AccordionItem>
                      </AccordionItemHolder>


                      <AccordionItemHolder>
                        <AccordionItem index={3}>
                          <AccordionHead>

                            <AccordionIcon icons={['plus', 'minus']} />
                            <h6 className={'h6'}>How much should I pay for your service?</h6>

                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</p>
                          </AccordionBody>
                        </AccordionItem>
                        <AccordionItem index={4}>

                          <AccordionHead>
                            <AccordionIcon icons={['plus', 'minus']} />
                            <h6 className={'h6'}>How can I be sure that my money is secure?</h6>

                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</p>
                          </AccordionBody>
                        </AccordionItem>
                      </AccordionItemHolder>

                    </Accordion>

                  </Holder>
                </Faq2>

                <Footer />

              </div>
            </LocalesPage>
          )
        }}
      </WithHook>
    )

  }
}

export default Landing2;
